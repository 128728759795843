import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Rayhan Patel </span>
            from <span className="purple"> Hyderabad, India.</span>
            <br /> I am a final-year undergraduate student studying Computer Science and Engineering with a growing interest in Data Science. 
            Passionate about technology and its transformative potential, I strive to combine my technical skills with data-driven insights to solve complex problems.
            {/* <br /> */}
            {/* Additionally, I am currently employed as a software developer at */}
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Writing Tech Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> In depth research
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Computer science: Building wonders with limitless Lego blocks."{" "}
          </p>
          <footer className="blockquote-footer">Rayhan</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
