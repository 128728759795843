import React from "react";
import { Container} from "react-bootstrap";
// import {Row, Col} from "react-bootstrap";
// import ProjectCard from "./ProjectCards";
import Particle from "../Particle";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p style={{ color: "white" }}>
          Coming Soon.
        </p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
    </Container>
  );
}

export default Projects;
